import React from 'react';

function Footer() {
  return (
    <footer>
      <p>Contact Info | Social Media Links | <a href="resume-pdf-link">Download Resume (PDF)</a></p>
    </footer>
  );
}

export default Footer;
