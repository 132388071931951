import React from 'react';

function Education() {
  return (
    <section className="education">
      <h2>Education</h2>
      <h3>Bachelor of Arts - Utah State University</h3>
      <ul>
        <li>double major: political science, journalism</li>
      </ul>
    </section>
  );
}

export default Education;
