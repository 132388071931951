import React from 'react';

function Projects() {
  return (
    <section className="projects">
      <h2>Projects</h2>
      <h3>Project Name</h3>
      <p>Short description of the project.</p>
      <p>Technologies used: Tech1, Tech2</p>
      <a href="project-demo-link">Demo</a> | <a href="project-source-code-link">Source Code</a>
      {/* Add more project entries as needed */}
    </section>
  );
}

export default Projects;
