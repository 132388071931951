import React from 'react';

function Header() {
  return (
    <header>
      <img src="/images/benHead.jpg" alt="Ben Walker" />
      <h1>Ben Walker</h1>
      <p>Software engineering manager, full stack software engineer</p>
      <p>benwalker14@gmail.com | 435-535-1661 | <a href="https://www.linkedin.com/in/ben-walker-16101b50/" target="_blank" rel="noopener noreferrer">LinkedIn</a> | <a href="https://github.com/benwalker14" target="_blank" rel="noopener noreferrer">GitHub</a></p>
    </header>
  );
}

export default Header;
