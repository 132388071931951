import React from 'react';

function Experience() {
  return (
    <section className="experience">
      <h2>Experience</h2>
      <h3>Engineering Manager - tvScientific</h3>
      <p>2022 - current</p>
      <ul>
        <li>leading a full stack team (Python Django/React)</li>
        <li>8 Direct reports</li>
        <li>...</li>
      </ul>

      <h3>Engineering Manager - Rakuten Advertising</h3>
      <p>2015 - 2022</p>
      <ul>
        <li>Managed full stack team (Go/React)</li>
        <li>Built single sign on product</li>
        <li>Reporting data API</li>
        <li>...</li>
      </ul>
    </section>
  );
}

export default Experience;
