import React from 'react';

function Skills() {
  return (
    <section className="skills">
      <h2>Skills</h2>
      <ul>
        <li>Programming Languages: C#, Go, Python, JavaScript</li>
        <li>Frameworks: React, Django</li>
        <li>DB: SQL Server, Mysql, Postgresql</li>
        <li>Tools: Git</li>
      </ul>
    </section>
  );
}

export default Skills;
