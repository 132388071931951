import React from 'react';

function AboutMe() {
  return (
    <section className="about-me">
      <h2>About Me</h2>
      <p></p>
    </section>
  );
}

export default AboutMe;
